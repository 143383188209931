@import url("https://cdn.syncfusion.com/ej2/material.css");
.App {
  text-align: center;
}


.container {
  display: flex;
}

.box {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: lightblue;
  border: 1px solid blue;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.messagediv{
    @apply h-auto w-auto max-w-96  mx-5 self-end my-2 text-white px-4 py-2 bg-[#4285F4] border border-black rounded-xl
}
.responsediv{
    @apply h-auto w-auto max-w-96  mx-5 self-start my-2 px-4 py-2 bg-white border border-black rounded-xl
}
