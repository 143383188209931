.imageAnalyzerform{
    display: flex;
    background-color: rgb(251, 251, 202);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    height: 400px;
    width: 750px;
    cursor: pointer;
    border-radius: 8px;
    margin-top:0rem
}
.uploaded-row{
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #ecfc86;
}
.upload-content{
    display: flex;
    align-items: center;
}